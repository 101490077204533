<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <v-card>
          <v-toolbar
            color="primary"
            dark
          >
            <v-toolbar-title>
              <v-icon class="mr-2">mdi-cog</v-icon>{{$t('base.setting')}}
            </v-toolbar-title>

          </v-toolbar>
          <v-tabs
            class="mt-2"
            v-model="tab"
            centered
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#profile-setting-general">
              {{$t('base.general')}}
              <v-icon left>mdi-account</v-icon>
            </v-tab>

            <v-tab href="#profile-setting-security">
              {{$t('base.security')}}
              <v-icon left>mdi-lock</v-icon>
            </v-tab>

            <v-tab href="#profile-setting-more">
              {{$t('nav.more')}}
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item :value="'profile-setting-general'">
              <general
                v-model="currentUser"
                :processing="isProcessing"
                @upload-avatar="onUploadAvatar"
              />
            </v-tab-item>
            <v-tab-item :value="'profile-setting-security'">
              <v-card flat>
                <security
                  :processing="isProcessing"
                  @save="onChangePassword"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item :value="'profile-setting-more'">
              <v-card flat>
                <v-card-text>{{ text }}</v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>

    </v-row>
    <wee-toast ref="weeToastRef" />
  </v-container>
</template>

<script>
import useSiteSetting from "@/composition/UseSiteSetting";
import useBase from "@/composition/UseBase";
import AuthService from "@/api/AuthService";
import UserService from "@/api/UserService";
import { ref } from "@vue/composition-api";
export default {
  components: {
    WeeToast: () => import("@/components/WeeToast"),
    General: () => import("@/views/App/components/profile/settings/General"),
    Security: () => import("@/views/App/components/profile/settings/Security")
  },
  setup(props, { refs, root }) {
    const authService = new AuthService();
    const userService = new UserService();
    const { currentUser } = useSiteSetting(root);
    const { toast, baseLogout, baseUpdateUserStore } = useBase(refs, root);

    const isProcessing = ref(false);
    const tab = ref(null);
    const text = ref(
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    );
    const onChangePassword = async postData => {
      if (!postData) {
        return;
      }
      isProcessing.value = true;
      const { response, error } = await authService.changePassword(postData);
      isProcessing.value = false;
      if (error) {
        toast(false, error, { timeout: 5000 });
        return;
      }
      toast(response.status, response.message, { timeout: 5000 });
      if (response.status) {
        baseLogout();
      }
    };
    const onUploadAvatar = async postData => {
      if (!postData) {
        return;
      }
      isProcessing.value = true;
      const { response, error } = await userService.changeAvatar(postData);
      isProcessing.value = false;
      if (error) {
        toast(false, error, { timeout: 5000 });
        return;
      }
      toast(response.status, response.message, { timeout: 5000 });
      if (response && response.picture) {
        baseUpdateUserStore({ picture: response.picture });
      }
    };

    return {
      isProcessing,
      currentUser,
      tab,
      text,
      onChangePassword,
      onUploadAvatar,
    };
  }
};
</script>